.navbar {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  padding: 23px 0;
  background-color: transparent;
  transition: all 0.3s ease;
  transform: translateY(0);

  &.scrolledUp {
    transform: translateY(-100%);
  }

  &.scrolledDown {
    transform: translateY(0);
    border-bottom: 1px solid $grey;
    background-color: $milky;

    .btn-primary {
     opacity: 1;
    }
  }

  .container {
    justify-content: space-between;
    align-items: center;
  }

  &-menu {
    position: fixed;
    top: 81px;
    left: 0;
    right: 0;
    height: 0;
    width: 100vw;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s ease;
    transform: translateX(-100%);
    padding: 65px 30px 60px;
    display: flex;

    &::before {
      position: absolute;
      content: '';
      top: -81px;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      width: 100%;
      background-color: $milky;
      opacity: 0.83;
      z-index: -1;
    }

    &::after {
      position: absolute;
      content: '';
      top: -81px;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      width: 100%;
      background-image: url('../img/menu-bg.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right bottom;
      z-index: -2;
    }

    &-active {
      background-color: $milky;

      &.scrolledDown,
      &.scrolledUp {
        transform: translateY(0);
        border-bottom: none;

        .btn-primary {
          opacity: 0;
        }
      }

      .buttons,
      .logo-wrap {
        z-index: 999;
      }

      .burger {
        position: relative;
        width: 33px;
        height: 33px;

        &:hover {
          span {
            background-color: $primary;
          }
        }

        span {
          position: absolute;
          top: 35%;
          right: 17%;

          &:nth-of-type(1) {
            transform: rotate(135deg);
          }

          &:nth-of-type(3) {
            transform: rotate(45deg);
          }

          &:nth-of-type(2) {
            opacity: 0;
          }
        }
      }

      .navbar-menu {
        height: 85vh;
        height: calc(100vh - 81px);
        z-index: 998;
        opacity: 1;
        bottom: 0;
        background-color: $milky;
        transform: translateX(0);
      }
    }

    @include lg {
      padding: 65px 15px 60px;
    }

    @include md {
      padding: 35px 15px 20px;
    }
  }

  .menu {
    margin: 0;
    padding: 0;
    list-style: none;

    &-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 1220px;
      width: 100%;
      margin: 0 auto;
    }

    &-item {
      margin-bottom: 40px;
    }

    &-link {
      text-decoration: none;
      font-weight: 500;
      font-size: 48px;
      line-height: 59px;
      color: $text;
      transition: color 0.3s ease;

      &:hover {
        color: $primary;
      }

      @include md {
        font-size: 36px;
        line-height: 44px;
      }
    }
  }

  .motto {
    font-size: 18px;
    line-height: 22px;
    max-width: 320px;
    display: inline-block;


    @include md {
      font-size: 14px;
      line-height: 17px;
      max-width: 230px;
    }
  }

  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &-wrap {
      max-width: 216px;
      max-height: 35px;
      margin-right: 15px;

      @include md {
        max-width: 160px;
        max-height: 30px;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
  }

  .btn-primary {
    opacity: 0;
  }

  .burger {
    margin-left: 30px;

    @include md {
      margin-left: 15px;
    }
  }
}
