.arrow {
  height: 12px;

  .chevron {
    fill: none;
    stroke: $text;
    stroke-width: 2;
  }

  .line {
    fill: $text;
    width: 14px;
    height: 2px;
    transition: width 0.3s ease;
  }

  &:hover,
  &:focus {
    .line {
      width: 36px;
    }
  }

  &:active {
    .line {
      width: 14px;
    }
  }
}
