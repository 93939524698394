.small-page {
  padding: 120px 0 160px;
  min-height: calc(100vh - 328px);

    .image-primary {
      max-width: 140px;
      max-height: 180px;
    }

  .content {
    max-width: 300px;

    .text {
      max-width: 200px;
      margin-bottom: 50px;

      @include sm {
        text-align: center;
      }
    }
  }
}
