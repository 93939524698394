.caring-tab {
  padding: 100px 0 65px;

  .container {
    flex-direction: column;
  }

  .title {
    max-width: 640px;
    margin-bottom: 20px;

    @include md {
      max-width: none;
      margin-bottom: 15px;
    }

    @include sm {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .text {
    max-width: 640px;
    margin-bottom: 40px;

    @include md {
      font-size: 14px;
      line-height: 18px;
    }

    @include sm {
      line-height: 20px;
      margin-bottom: 30px;
    }
  }

  .tabs {
    width: 52.46%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-wrap {
      display: flex;
      justify-content: space-between;
    }

    @include lg {
      width: 100%;
    }
  }

  .tab {
    cursor: pointer;
    width: 46.875%;
    margin-bottom: 35px;
    display: flex;

    &:hover {
      .tab-title {
        color: $primary;
      }
    }

    &.active {
      .tab {
        &-topic {
          .line {
            width: 8px;
          }
        }

        &-title {
          color: $primary;
        }
      }

      .text {
        margin-bottom: 0;
      }
    }

    &-topic {
      display: flex;
      position: relative;
      padding-left: 20px;
      margin-bottom: auto;
      height: 100%;

      .line {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 4px;
        background-color: $primary;
        transition: all 0.3s ease;

        @include md {
          height: 100%;
        }
      }

      @include md {
        height: initial;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $text;
      transition: color 0.3s ease;
      min-height: 50px;
    }

    &-image {
      max-height: 27px;
      object-fit: contain;
      margin-right: 10px;
    }

    &-item {
      display: none;
      flex-direction: column;

      .topic {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 22px;

        @include md {
          font-size: 18px;
        }
      }

      .text {
        @include lg {
          margin-top: 10px;
        }
      }

      @include lg {
        width: 100%;
        margin-top: 35px;
      }
    }

    &-content {
      width: 46.722%;
      display: flex;
      padding-left: 40px;
      border-left: 1px solid $light-grey;
      margin-bottom: auto;

      .tab-item {
        display: flex;

        .text {
          margin-bottom: 0;
        }
      }

      @include lg {
        display: none;
      }
    }

    @include lg {
      flex-wrap: wrap;
    }

    @include lg {
      width: 48.589%;
    }

    @include sm {
      width: 100%;
    }
  }

  @include md {
    padding: 60px 0 25px;
  }

  @include sm {
    padding: 40px 0 5px;
  }
}
