.representation {
  padding: 180px 0;

  .container {
    position: relative;
    justify-content: space-between;

    .rectangle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 400px;
      height: 145%;
      height: calc(100% + 80px);
      z-index: -2;

      span {
        position: absolute;
        display: block;
        background-color: $light-purple;

        &:nth-of-type(1) {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 5px;

          @include md {
            width: 100%;
          }
        }

        &:nth-of-type(4) {
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 5px;

          @include md {
            width: 100%;
          }
        }

        &:nth-of-type(2) {
          top: 0;
          left: 0;
          width: 5px;

          @include md {
            height: 100%;
          }
        }

        &:nth-of-type(3) {
          top: 0;
          right: 0;
          width: 5px;

          @include md {
            height: 100%;
          }
        }
      }

      @include rwd(1200) {
        width: 500px;
        height: 125%;
        height: calc(100% + 100px);
      }

      @include sm {
        width: 85%;
        height: 120%;
        height: calc(100% + 60px);
      }
    }

    @include rwd(1200) {
      flex-direction: column;
    }
  }

  .item {
    width: 48.771%;
    display: flex;
    padding: 40px;
    justify-content: space-between;
    position: relative;

    &-left {
      padding-left: 0;

      &::after {
        right: 0;
      }

      .image-wrap {
        margin-left: 20px;

        @include rwd(1200) {
          margin-left: 40px;
        }

        @include sm {
          margin-left: 10px;
        }
      }

      .text, .title {
        text-align: right;

        @include rwd(1200) {
          text-align: left;
        }
      }

      .title {
        align-self: flex-end;

        @include rwd(1200) {
          align-self: flex-start;
        }
      }

      @include rwd(1200) {
        margin-bottom: 30px;
      }
    }

    &-right {
      flex-direction: row-reverse;
      padding-right: 0;

      &::after {
        left: 0;
      }

      .image-wrap {
        margin-right: 20px;

        @include rwd(1200) {
          margin-right: 40px;
        }

        @include sm {
          margin-right: 10px;
        }
      }

      @include rwd(1200) {
        align-self: flex-end;
      }
    }

    &::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      width: 100vw;
      height: 100%;
      z-index: -1;
      background-color: $white;
      border: 1px solid $light-grey;
    }

    @include rwd(1200) {
      width: 70%;
      justify-content: flex-end;
    }

    @include lg {
      width: 80.51%;
    }

    @include md {
      width: 82%;
    }

    @include sm {
      padding: 20px 10px;
      width: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    max-width: 414px;

    @include rwd(1200) {
      max-width: 470px;
    }
  }

  .title {
    margin-bottom: 20px;

    @include md {
      margin-bottom: 15px;
    }

    @include sm {
      margin-bottom: 10px;
    }
  }

  .text {
    @include md {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &-wrap {
      min-width: 100px;
      height: 100px;

      @include md {
        min-width: 80px;
        height: 80px;
      }

      @include sm {
        min-width: 60px;
        height: 60px;
      }
    }
  }

  @include lg {
    padding: 130px 0;
  }

  @include md {
    padding: 110px 0;
  }

  @include sm {
    padding: 70px 0;
  }
}
