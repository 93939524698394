.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.form-error {
    position: relative;

    .message-error {
        display: flex;
      }
  }

  &.form-success {
    position: relative;

    .message-success {
        display: flex;
      }
  }

  &.form-loaded {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 30px;
      width: 30px;
      bottom: 2px;
      left: 0;
      right: 0;
      margin: auto;
      display: inline-block;
      border: 3px solid rgba($primary,.3);
      border-radius: 50%;
      border-top-color: $primary;
      animation: spin 1s ease-in-out infinite;
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      height: 60px;
      width: 100%;
      bottom: -11px;
      left: 0;
      background-color: $milky;
    }
  }

  &-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 350px;

    .privacy-terms {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: $text;
      margin-top: 15px;

      a {
        text-decoration: none;
        cursor: pointer;
        transition: color 0.3s ease;
        font-weight: 700;
        color: $primary;

        &:hover {
          color: $dark-blue;
        }
      }
    }

    .title {
      margin-bottom: 20px;
      text-align: center;

      @include md {
        margin-bottom: 15px;
      }

      @include sm {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .text {
      text-align: center;
      margin-bottom: 30px;

      @include md {
        font-size: 14px;
        line-height: 18px;
      }

      @include sm {
        line-height: 20px;
        margin-bottom: 20px;
      }
    }

    .message {
      &-error, &-success {
        display: none;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 2;
        background-color: $milky;
        padding-bottom: 23px;

        .topic {
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: $red-true;
        }

        .subtopic {
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          margin: 0;
          opacity: 0.7;
        }
      }

      &-success {
        .topic {
          color: $green;
        }
      }
    }

    .form-group {
      margin-bottom: 15px;
      width: 100%;
      display: flex;
      position: relative;

      &.active {
        .label-text {
          font-size: 8px;
          line-height: 10px;
          top: 9px;
        }
      }

      &.error {
        .input, .textarea {
          border-color: $red;
        }
      }

      &.disabled {
        cursor: initial;
        opacity: 0.4;

        .label:hover {
          .input, .textarea {
            border-color: rgba($text, 0.16);
          }
        }
      }

      .input-error {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: right;
        color: $red;
        position: absolute;
        right: 0;
        bottom: -13px;
      }
    }


    .btn {
      margin-top: 15px;
      justify-content: center;
    }
  }

  .label {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    &-text {
      cursor: text;
      display: flex;
      position: absolute;
      top: 15px;
      left: 15px;
      font-weight: 400;
      line-height: 19px;
      opacity: 0.5;
      transition: all 0.3s ease;
    }

    &:hover {
      .input, .textarea {
        border-color: $text;
      }
    }
  }

  .input, .textarea {
    padding: 21px 15px 10px;
    background: $white;
    border: 1px solid rgba($text, 0.16);
    border-radius: 2px;
    resize: none;
    width: 100%;
    font-family: $default-font;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    transition: all 0.3s ease;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      border-color: $text;
    }
  }

  .textarea {
    min-height: 100px;
  }
}
