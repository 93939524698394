.btn {
  outline: none;
  border: none;
  background-color: $primary;
  font-weight: 500;
  color: $white;
  text-decoration: none;

  &-primary {
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    padding: 14px 28px;
    position: relative;
    transition: all 0.3s ease;
    border: 2px solid $primary;

    &::before,
    &::after {
      display: inline-block;
      content: '';
      width: 5px;
      height: 5px;
      border-top: 1px solid $white;
      border-right: 1px solid $white;
      transform: rotate(45deg);
      opacity: 1;
      transition: all 0.3s ease;
    }

    &::before {
      margin: 0;
      opacity: 0;
      width: 0;
      height: 0;
    }

    &::after {
      margin-left: 12px;
    }

    &:hover {
      &::before {
        margin-right: 12px;
        opacity: 1;
        width: 5px;
        height: 5px;
      }

      &::after {
        margin: 0;
        opacity: 0;
        width: 0;
        height: 0;
      }
    }

    &:focus {
      border-color: $dark-blue;
    }

    &:active {
      border-color: $dark-blue;
      background-color: $dark-blue;
    }

    &:disabled {
      cursor: auto;
      opacity: 0.2;

      &::before {
        margin: 0;
        opacity: 0;
        width: 0;
        height: 0;
      }

      &::after {
        margin-left: 12px;
        width: 5px;
        height: 5px;
        opacity: 1;
      }
    }

    &-small {
      padding: 8px 18px;
      font-size: 14px;
      line-height: 15px;
    }
  }
}

.burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 5px;

  &:hover, &:active {
    span:nth-of-type(2) {
      margin-left: 0;
    }
  }

  &:active {
   span {
     background-color: $primary;
   }
  }

  span {
    display: flex;
    height: 2px;
    width: 22px;
    background-color: $text;
    margin: 3px 0;
    transition: all 0.3s ease;

    &:nth-of-type(2) {
      margin-left: -7px;
    }
  }
}
