.footer {
  color: $white;
  background-color: $light-black;

  a:hover {
    color: $primary;
  }

  a:focus,
  a:active {
    color: $dark-blue;
  }

  .container {
    flex-direction: column;
  }

  .main {
    display: flex;
    justify-content: space-between;
    padding: 50px 0 20px;

    &-left {
      display: flex;
      width: 48%;
      flex-direction: column;

      @include rwd(715) {
        width: 100%;
      }
    }

    &-right {
      display: flex;
      width: 41.8%;
      margin-top: 64px;
      justify-content: flex-end;

      @include rwd(715) {
        margin-top: 25px;
        width: 100%;
        justify-content: flex-start;
      }

      @include sm {
        margin-top: 15px;
      }
    }

    @include md {
      padding: 40px 0 15px;
    }

    @include rwd(715) {
      flex-direction: column;
      padding: 40px 0;
    }

    @include sm {
      padding: 30px 0;
    }
  }

  .address {
    font-family: $default-font;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba($white, 0.3);

    &-wrap {
      display: flex;
      padding-bottom: 10px;
      flex-wrap: wrap;
    }

    &:first-of-type {
      margin-right: 5px;
    }

    &-link {
      cursor: pointer;
      text-decoration: none;
      color: rgba($white, 0.8);
      transition: color 0.3s ease;
    }
  }

  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &-wrap {
      max-width: 135px;
      max-height: 29px;
      margin-bottom: 30px;

      @include sm {
        margin-bottom: 25px;
        max-width: 115px;
        max-height: 13px;
      }
    }
  }

  .link {
    color: inherit;
    text-decoration: none;
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    opacity: 0.8;
    display: inline-block;
    transition: color 0.3s ease;

    &:first-of-type {
      padding-left: 0;
    }

    &-wrap {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
    }

    &-item {
      margin: 0 20px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      @include lg {
        margin: 0 10px;
      }

      @include rwd(450) {
        margin: 0 5px;
      }
    }

    @include sm {
      opacity: 1;
    }

    @include rwd(450) {
      padding: 10px 5px;
      font-size: 12px;
    }
  }

  .bottom {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      background-color: $black;
      z-index: -1;
    }

    .copyright {
      @include rwd(715) {
        margin: 0 auto;
        order: 2;
        font-size: 8px;
        line-height: 10px;
      }
    }

    &-links {
      display: flex;

      a {
        color: inherit;
        text-decoration: none;
        padding: 0 10px;
        border-right: 1px solid $white;
        transition: color 0.3s ease;

        &:last-of-type {
          border-right: none;
          padding-right: 0;
        }

        &:first-of-type {
          @include rwd(715) {
            padding-left: 0;
          }
        }

        &:hover {
          color: $primary;
        }

        &:focus,
        &:active {
          color: $dark-blue;
        }
      }

      @include rwd(715) {
        margin: 0 auto 15px;
        order: 1;
      }
    }

    @include rwd(715) {
      flex-direction: column;
      justify-content: center;
      padding: 15px 0 20px;
    }
  }
}
