@import "vars";
@import "fonts";
@import "libs";
@import "mixins/media";

body {
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  min-width: 320px;
  position: relative;
  font-family: $default-font;
  overflow-x: hidden;
  color: $text;
  //padding-top: 81px;

  input,
  textarea {
    //border: #666 1px solid;
    outline: none;

    &:focus:required:invalid {
     // border-color: red;
    }

    &:required:valid {
     // border-color: green;
    }
  }

  &.overflow-hidden {
    //@include sm {
      overflow: hidden !important;
      //position: fixed !important;
    //}
  }
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

b,
strong {
  font-weight: 700;
}

.container {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;

  @include rwd(1656) {
    padding: 0 30px;
  }

  @include sm {
    padding: 0 15px;
  }
}

section {
  overflow-x: hidden;
}

button, a {
  cursor: pointer;
}

[data-type="parallax"] {
  transition: margin 0.2s linear;
}

// Animations
@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

[data-aos="opacity"] {
  opacity: 0;

  &.aos-animate {
    animation-name: setOpacity;
    animation-timing-function: linear;
    opacity: 1;
  }
}

[data-aos="line"] {
  height: 0;

  &.aos-animate {
    animation-name: lineAnimation;
    animation-timing-function: linear;
    height: 100%;
  }
}

[data-aos="rectangleTop"] {
  width: 0;

  &.aos-animate {
    animation-name: drawRectangleTop;
    animation-timing-function: linear;
    width: 100%;
  }
}

[data-aos="rectangleSides"] {
  height: 0;

  &.aos-animate {
    animation-name: lineAnimation;
    animation-timing-function: linear;
    height: 100%;
  }
}

[data-aos="triangle"] {
  border-bottom:solid 60px $white;
  border-right:solid 30px transparent;
  border-left:solid 30px transparent;
  margin-top: 330px;

  &.aos-animate {
    animation-name: drawTriangle;
    animation-timing-function: linear;
    border-bottom:solid 270px $white;
    border-right:solid 170px transparent;
    border-left:solid 170px transparent;
    margin-top: 210px;
  }
}

[data-aos] {
  body[data-aos-easing="fists"] &,
  &[data-aos][data-aos-easing="fists"] {
    transition-timing-function: cubic-bezier(.69,.01,.79,.4);
  }
}

@keyframes setOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lineAnimation {
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
}

@keyframes drawRectangleTop {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes drawTriangle {
  0% {
    border-bottom:solid 60px $white;
    border-right:solid 30px transparent;
    border-left:solid 30px transparent;
    margin-top: 330px;
  }

  100% {
    border-bottom:solid 270px $white;
    border-right:solid 170px transparent;
    border-left:solid 170px transparent;
    margin-top: 210px;
  }
}

// UI elements
@import "UI/buttons";
@import "UI/navbar";
@import "UI/footer";
@import "UI/title";
@import "UI/text";
@import "UI/card";
@import "UI/arrow";
@import "UI/contact-form";
@import "UI/small-page";

// Pages elements
.home-page {
  .footer {
    margin-top: 60px;

    @include md {
      margin-top: 20px;
    }
  }

  @import "pages/home/header";
  @import "pages/home/partner";
  @import "pages/home/representation";
  @import "pages/home/deal";
  @import "pages/home/advantages";
  @import "pages/home/voltore";
  @import "pages/home/we-know";
  @import "pages/home/success";
  @import "pages/home/market-tab";
  @import "pages/home/transactions";
  @import "pages/home/caring-tab";
  @import "pages/home/contacts";
}

.not-found-page, .thanks-page, .error-page {
  padding-top: 81px;

  @import "pages/not-found";
}

.terms-page {
  padding-top: 81px;

  @import "pages/terms";
  @import "pages/home/we-know";
}

.blog-page {
  padding-top: 30px;
  @import "pages/terms";
  @import "pages/home/we-know";

  .we-know {
    padding: 110px 0 80px;
  }

  @include sm {
    padding-top: 50px;
  }
}

@keyframes progressBar {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}
