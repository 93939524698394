@import url(../../node_modules/swiper/swiper-bundle.min.css);

@import url(../../node_modules/aos/dist/aos.css);

@font-face {
  font-family: "Helvetica Neue";
  font-weight: 100;
  font-style: normal;
  src: url("../../fonts/HelveticaNeue-Thin/HelveticaNeue-Thin.eot");
  src: url("../../fonts/HelveticaNeue-Thin/HelveticaNeue-Thin.eot?#iefix") format("embedded-opentype"), url("../../fonts/HelveticaNeue-Thin/HelveticaNeue-Thin.woff") format("woff"), url("../../fonts/HelveticaNeue-Thin/HelveticaNeue-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Neue";
  font-weight: 200;
  font-style: normal;
  src: url("../../fonts/HelveticaNeue-UltraLight/HelveticaNeue-UltraLight.eot");
  src: url("../../fonts/HelveticaNeue-UltraLight/HelveticaNeue-UltraLight.eot?#iefix") format("embedded-opentype"), url("../../fonts/HelveticaNeue-UltraLight/HelveticaNeue-UltraLight.woff") format("woff"), url("../../fonts/HelveticaNeue-UltraLight/HelveticaNeue-UltraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Neue";
  font-weight: 300;
  font-style: normal;
  src: url("../../fonts/HelveticaNeue-Light/HelveticaNeue-Light.eot");
  src: url("../../fonts/HelveticaNeue-Light/HelveticaNeue-Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/HelveticaNeue-Light/HelveticaNeue-Light.woff") format("woff"), url("../../fonts/HelveticaNeue-Light/HelveticaNeue-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Neue";
  font-weight: 400;
  font-style: normal;
  src: url("../../fonts/HelveticaNeue-Roman/HelveticaNeue-Roman.eot");
  src: url("../../fonts/HelveticaNeue-Roman/HelveticaNeue-Roman.eot?#iefix") format("embedded-opentype"), url("../../fonts/HelveticaNeue-Roman/HelveticaNeue-Roman.woff") format("woff"), url("../../fonts/HelveticaNeue-Roman/HelveticaNeue-Roman.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Neue";
  font-weight: 500;
  font-style: normal;
  src: url("../../fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.eot");
  src: url("../../fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.woff") format("woff"), url("../../fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Neue";
  font-weight: 700;
  font-style: normal;
  src: url("../../fonts/HelveticaNeue-Bold/HelveticaNeue-Bold.eot");
  src: url("../../fonts/HelveticaNeue-Bold/HelveticaNeue-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/HelveticaNeue-Bold/HelveticaNeue-Bold.woff") format("woff"), url("../../fonts/HelveticaNeue-Bold/HelveticaNeue-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  min-width: 320px;
  position: relative;
  font-family: "Helvetica Neue", sans-serif;
  overflow-x: hidden;
  color: #000;
}

body input,
body textarea {
  outline: none;
}

body.overflow-hidden {
  overflow: hidden !important;
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

b,
strong {
  font-weight: 700;
}

.container {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
}

section {
  overflow-x: hidden;
}

button,
a {
  cursor: pointer;
}

[data-type="parallax"] {
  transition: margin 0.2s linear;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

[data-aos="opacity"] {
  opacity: 0;
}

[data-aos="opacity"].aos-animate {
  animation-name: setOpacity;
  animation-timing-function: linear;
  opacity: 1;
}

[data-aos="line"] {
  height: 0;
}

[data-aos="line"].aos-animate {
  animation-name: lineAnimation;
  animation-timing-function: linear;
  height: 100%;
}

[data-aos="rectangleTop"] {
  width: 0;
}

[data-aos="rectangleTop"].aos-animate {
  animation-name: drawRectangleTop;
  animation-timing-function: linear;
  width: 100%;
}

[data-aos="rectangleSides"] {
  height: 0;
}

[data-aos="rectangleSides"].aos-animate {
  animation-name: lineAnimation;
  animation-timing-function: linear;
  height: 100%;
}

[data-aos="triangle"] {
  border-bottom: solid 60px #fff;
  border-right: solid 30px transparent;
  border-left: solid 30px transparent;
  margin-top: 330px;
}

[data-aos="triangle"].aos-animate {
  animation-name: drawTriangle;
  animation-timing-function: linear;
  border-bottom: solid 270px #fff;
  border-right: solid 170px transparent;
  border-left: solid 170px transparent;
  margin-top: 210px;
}

body[data-aos-easing="fists"] [data-aos],
[data-aos][data-aos][data-aos-easing="fists"] {
  transition-timing-function: cubic-bezier(0.69, 0.01, 0.79, 0.4);
}

@keyframes setOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lineAnimation {
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
}

@keyframes drawRectangleTop {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes drawTriangle {
  0% {
    border-bottom: solid 60px #fff;
    border-right: solid 30px transparent;
    border-left: solid 30px transparent;
    margin-top: 330px;
  }

  100% {
    border-bottom: solid 270px #fff;
    border-right: solid 170px transparent;
    border-left: solid 170px transparent;
    margin-top: 210px;
  }
}

.btn {
  outline: none;
  border: none;
  background-color: #4D55FE;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
}

.btn-primary {
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 18px;
  padding: 14px 28px;
  position: relative;
  transition: all 0.3s ease;
  border: 2px solid #4D55FE;
}

.btn-primary::before,
.btn-primary::after {
  display: inline-block;
  content: '';
  width: 5px;
  height: 5px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
  opacity: 1;
  transition: all 0.3s ease;
}

.btn-primary::before {
  margin: 0;
  opacity: 0;
  width: 0;
  height: 0;
}

.btn-primary::after {
  margin-left: 12px;
}

.btn-primary:hover::before {
  margin-right: 12px;
  opacity: 1;
  width: 5px;
  height: 5px;
}

.btn-primary:hover::after {
  margin: 0;
  opacity: 0;
  width: 0;
  height: 0;
}

.btn-primary:focus {
  border-color: #2026A0;
}

.btn-primary:active {
  border-color: #2026A0;
  background-color: #2026A0;
}

.btn-primary:disabled {
  cursor: auto;
  opacity: 0.2;
}

.btn-primary:disabled::before {
  margin: 0;
  opacity: 0;
  width: 0;
  height: 0;
}

.btn-primary:disabled::after {
  margin-left: 12px;
  width: 5px;
  height: 5px;
  opacity: 1;
}

.btn-primary-small {
  padding: 8px 18px;
  font-size: 14px;
  line-height: 15px;
}

.burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 5px;
}

.burger:hover span:nth-of-type(2),
.burger:active span:nth-of-type(2) {
  margin-left: 0;
}

.burger:active span {
  background-color: #4D55FE;
}

.burger span {
  display: flex;
  height: 2px;
  width: 22px;
  background-color: #000;
  margin: 3px 0;
  transition: all 0.3s ease;
}

.burger span:nth-of-type(2) {
  margin-left: -7px;
}

.navbar {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  padding: 23px 0;
  background-color: transparent;
  transition: all 0.3s ease;
  transform: translateY(0);
}

.navbar.scrolledUp {
  transform: translateY(-100%);
}

.navbar.scrolledDown {
  transform: translateY(0);
  border-bottom: 1px solid #A4A4A4;
  background-color: #F9F9F9;
}

.navbar.scrolledDown .btn-primary {
  opacity: 1;
}

.navbar .container {
  justify-content: space-between;
  align-items: center;
}

.navbar-menu {
  position: fixed;
  top: 81px;
  left: 0;
  right: 0;
  height: 0;
  width: 100vw;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateX(-100%);
  padding: 65px 30px 60px;
  display: flex;
}

.navbar-menu::before {
  position: absolute;
  content: '';
  top: -81px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  background-color: #F9F9F9;
  opacity: 0.83;
  z-index: -1;
}

.navbar-menu::after {
  position: absolute;
  content: '';
  top: -81px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  background-image: url("../img/menu-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
  z-index: -2;
}

.navbar-menu-active {
  background-color: #F9F9F9;
}

.navbar-menu-active.scrolledDown,
.navbar-menu-active.scrolledUp {
  transform: translateY(0);
  border-bottom: none;
}

.navbar-menu-active.scrolledDown .btn-primary,
.navbar-menu-active.scrolledUp .btn-primary {
  opacity: 0;
}

.navbar-menu-active .buttons,
.navbar-menu-active .logo-wrap {
  z-index: 999;
}

.navbar-menu-active .burger {
  position: relative;
  width: 33px;
  height: 33px;
}

.navbar-menu-active .burger:hover span {
  background-color: #4D55FE;
}

.navbar-menu-active .burger span {
  position: absolute;
  top: 35%;
  right: 17%;
}

.navbar-menu-active .burger span:nth-of-type(1) {
  transform: rotate(135deg);
}

.navbar-menu-active .burger span:nth-of-type(3) {
  transform: rotate(45deg);
}

.navbar-menu-active .burger span:nth-of-type(2) {
  opacity: 0;
}

.navbar-menu-active .navbar-menu {
  height: 85vh;
  height: calc(100vh - 81px);
  z-index: 998;
  opacity: 1;
  bottom: 0;
  background-color: #F9F9F9;
  transform: translateX(0);
}

.navbar .menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navbar .menu-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1220px;
  width: 100%;
  margin: 0 auto;
}

.navbar .menu-item {
  margin-bottom: 40px;
}

.navbar .menu-link {
  text-decoration: none;
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;
  color: #000;
  transition: color 0.3s ease;
}

.navbar .menu-link:hover {
  color: #4D55FE;
}

.navbar .motto {
  font-size: 18px;
  line-height: 22px;
  max-width: 320px;
  display: inline-block;
}

.navbar .logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.navbar .logo-wrap {
  max-width: 216px;
  max-height: 35px;
  margin-right: 15px;
}

.navbar .buttons {
  display: flex;
  align-items: center;
}

.navbar .btn-primary {
  opacity: 0;
}

.navbar .burger {
  margin-left: 30px;
}

.footer {
  color: #fff;
  background-color: #2B2B2B;
}

.footer a:hover {
  color: #4D55FE;
}

.footer a:focus,
.footer a:active {
  color: #2026A0;
}

.footer .container {
  flex-direction: column;
}

.footer .main {
  display: flex;
  justify-content: space-between;
  padding: 50px 0 20px;
}

.footer .main-left {
  display: flex;
  width: 48%;
  flex-direction: column;
}

.footer .main-right {
  display: flex;
  width: 41.8%;
  margin-top: 64px;
  justify-content: flex-end;
}

.footer .address {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.3);
}

.footer .address-wrap {
  display: flex;
  padding-bottom: 10px;
  flex-wrap: wrap;
}

.footer .address:first-of-type {
  margin-right: 5px;
}

.footer .address-link {
  cursor: pointer;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.3s ease;
}

.footer .logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer .logo-wrap {
  max-width: 135px;
  max-height: 29px;
  margin-bottom: 30px;
}

.footer .link {
  color: inherit;
  text-decoration: none;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  opacity: 0.8;
  display: inline-block;
  transition: color 0.3s ease;
}

.footer .link:first-of-type {
  padding-left: 0;
}

.footer .link-wrap {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.footer .link-item {
  margin: 0 20px;
}

.footer .link-item:first-of-type {
  margin-left: 0;
}

.footer .link-item:last-of-type {
  margin-right: 0;
}

.footer .bottom {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
}

.footer .bottom::after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-color: #191818;
  z-index: -1;
}

.footer .bottom-links {
  display: flex;
}

.footer .bottom-links a {
  color: inherit;
  text-decoration: none;
  padding: 0 10px;
  border-right: 1px solid #fff;
  transition: color 0.3s ease;
}

.footer .bottom-links a:last-of-type {
  border-right: none;
  padding-right: 0;
}

.footer .bottom-links a:hover {
  color: #4D55FE;
}

.footer .bottom-links a:focus,
.footer .bottom-links a:active {
  color: #2026A0;
}

.title {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  margin: 0;
}

.text {
  margin: 0;
}

.card {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #000;
  width: 260px;
}

.card:hover .image {
  filter: grayscale(0);
}

.card .image {
  filter: grayscale(100%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}

.card .image-wrap {
  width: 260px;
  height: 268px;
  display: flex;
  flex-direction: column;
}

.card .image-wrap .line {
  width: 100%;
  height: 8px;
  background-color: #4D55FE;
}

.card .content {
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px;
}

.card .content .name {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
}

.card .content .price-wrap {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-top: 15px;
}

.card .content .cap-rate-wrap,
.card .content .noi-wrap {
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;
}

.arrow {
  height: 12px;
}

.arrow .chevron {
  fill: none;
  stroke: #000;
  stroke-width: 2;
}

.arrow .line {
  fill: #000;
  width: 14px;
  height: 2px;
  transition: width 0.3s ease;
}

.arrow:hover .line,
.arrow:focus .line {
  width: 36px;
}

.arrow:active .line {
  width: 14px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-form.form-error {
  position: relative;
}

.contact-form.form-error .message-error {
  display: flex;
}

.contact-form.form-success {
  position: relative;
}

.contact-form.form-success .message-success {
  display: flex;
}

.contact-form.form-loaded {
  position: relative;
}

.contact-form.form-loaded::before {
  content: '';
  position: absolute;
  height: 30px;
  width: 30px;
  bottom: 2px;
  left: 0;
  right: 0;
  margin: auto;
  display: inline-block;
  border: 3px solid rgba(77, 85, 254, 0.3);
  border-radius: 50%;
  border-top-color: #4D55FE;
  animation: spin 1s ease-in-out infinite;
  z-index: 1;
}

.contact-form.form-loaded::after {
  content: '';
  position: absolute;
  height: 60px;
  width: 100%;
  bottom: -11px;
  left: 0;
  background-color: #F9F9F9;
}

.contact-form-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
}

.contact-form-wrap .privacy-terms {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000;
  margin-top: 15px;
}

.contact-form-wrap .privacy-terms a {
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
  font-weight: 700;
  color: #4D55FE;
}

.contact-form-wrap .privacy-terms a:hover {
  color: #2026A0;
}

.contact-form-wrap .title {
  margin-bottom: 20px;
  text-align: center;
}

.contact-form-wrap .text {
  text-align: center;
  margin-bottom: 30px;
}

.contact-form-wrap .message-error,
.contact-form-wrap .message-success {
  display: none;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: #F9F9F9;
  padding-bottom: 23px;
}

.contact-form-wrap .message-error .topic,
.contact-form-wrap .message-success .topic {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #FF0000;
}

.contact-form-wrap .message-error .subtopic,
.contact-form-wrap .message-success .subtopic {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  margin: 0;
  opacity: 0.7;
}

.contact-form-wrap .message-success .topic {
  color: #0C8B09;
}

.contact-form-wrap .form-group {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact-form-wrap .form-group.active .label-text {
  font-size: 8px;
  line-height: 10px;
  top: 9px;
}

.contact-form-wrap .form-group.error .input,
.contact-form-wrap .form-group.error .textarea {
  border-color: #E41E1E;
}

.contact-form-wrap .form-group.disabled {
  cursor: initial;
  opacity: 0.4;
}

.contact-form-wrap .form-group.disabled .label:hover .input,
.contact-form-wrap .form-group.disabled .label:hover .textarea {
  border-color: rgba(0, 0, 0, 0.16);
}

.contact-form-wrap .form-group .input-error {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: #E41E1E;
  position: absolute;
  right: 0;
  bottom: -13px;
}

.contact-form-wrap .btn {
  margin-top: 15px;
  justify-content: center;
}

.contact-form .label {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.contact-form .label-text {
  cursor: text;
  display: flex;
  position: absolute;
  top: 15px;
  left: 15px;
  font-weight: 400;
  line-height: 19px;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.contact-form .label:hover .input,
.contact-form .label:hover .textarea {
  border-color: #000;
}

.contact-form .input,
.contact-form .textarea {
  padding: 21px 15px 10px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  resize: none;
  width: 100%;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  transition: all 0.3s ease;
  -moz-appearance: textfield;
}

.contact-form .input::-webkit-outer-spin-button,
.contact-form .input::-webkit-inner-spin-button,
.contact-form .textarea::-webkit-outer-spin-button,
.contact-form .textarea::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.contact-form .input:focus,
.contact-form .textarea:focus {
  border-color: #000;
}

.contact-form .textarea {
  min-height: 100px;
}

.small-page {
  padding: 120px 0 160px;
  min-height: calc(100vh - 328px);
}

.small-page .image-primary {
  max-width: 140px;
  max-height: 180px;
}

.small-page .content {
  max-width: 300px;
}

.small-page .content .text {
  max-width: 200px;
  margin-bottom: 50px;
}

.home-page .footer {
  margin-top: 60px;
}

.home-page .header {
  padding: 90px 0;
  position: relative;
}

.home-page .header::before {
  position: absolute;
  content: '';
  width: 45%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-bottom: 1px solid #A4A4A4;
}

.home-page .header::after {
  position: absolute;
  content: '';
  width: 55%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #F9F9F9;
  z-index: -1;
  border-left: 1px solid #A4A4A4;
  border-bottom: 1px solid #A4A4A4;
}

.home-page .header .image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.home-page .header .image-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 47%;
  z-index: -1;
}

.home-page .header .image-back {
  position: relative;
  z-index: -2;
  margin-top: 65px;
}

.home-page .header .container {
  justify-content: flex-end;
}

.home-page .header .content {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 65px;
}

.home-page .header .subtitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 10px;
  max-width: 500px;
}

.home-page .header .title {
  max-width: 500px;
  margin: 0 0 20px;
}

.home-page .header .text {
  display: block;
  max-width: 500px;
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 20px;
}

.home-page .header .list {
  margin: 10px 0;
  padding: 0;
  list-style: none;
}

.home-page .header .list-item {
  margin-bottom: 10px;
  padding-left: 15px;
  position: relative;
}

.home-page .header .list-item::before {
  content: '';
  position: absolute;
  height: 65%;
  width: 4px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: rgba(32, 38, 160, 0.2);
}

.home-page .header .list-text {
  margin: 0;
  font-style: italic;
  font-weight: 500;
  color: #2026A0;
}

.home-page .header .btn {
  margin-right: auto;
  margin-top: 30px;
}

.home-page .partner {
  padding: 120px 0 100px;
}

.home-page .partner .container {
  justify-content: space-between;
}

.home-page .partner .content {
  display: flex;
  flex-direction: column;
  width: 49.181%;
}

.home-page .partner .image {
  width: 100%;
  object-fit: contain;
}

.home-page .partner .image-wrap {
  width: 49.181%;
  position: relative;
}

.home-page .partner .image-back {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
}

.home-page .partner .title {
  margin-bottom: 20px;
}

.home-page .partner .text {
  margin: 0 0 10px;
  max-width: 450px;
}

.home-page .partner .partner-logo {
  margin-top: 50px;
  max-width: 180px;
  max-height: 50px;
  object-fit: contain;
}

.home-page .representation {
  padding: 180px 0;
}

.home-page .representation .container {
  position: relative;
  justify-content: space-between;
}

.home-page .representation .container .rectangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 145%;
  height: calc(100% + 80px);
  z-index: -2;
}

.home-page .representation .container .rectangle span {
  position: absolute;
  display: block;
  background-color: #F0F1FF;
}

.home-page .representation .container .rectangle span:nth-of-type(1) {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 5px;
}

.home-page .representation .container .rectangle span:nth-of-type(4) {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 5px;
}

.home-page .representation .container .rectangle span:nth-of-type(2) {
  top: 0;
  left: 0;
  width: 5px;
}

.home-page .representation .container .rectangle span:nth-of-type(3) {
  top: 0;
  right: 0;
  width: 5px;
}

.home-page .representation .item {
  width: 48.771%;
  display: flex;
  padding: 40px;
  justify-content: space-between;
  position: relative;
}

.home-page .representation .item-left {
  padding-left: 0;
}

.home-page .representation .item-left::after {
  right: 0;
}

.home-page .representation .item-left .image-wrap {
  margin-left: 20px;
}

.home-page .representation .item-left .text,
.home-page .representation .item-left .title {
  text-align: right;
}

.home-page .representation .item-left .title {
  align-self: flex-end;
}

.home-page .representation .item-right {
  flex-direction: row-reverse;
  padding-right: 0;
}

.home-page .representation .item-right::after {
  left: 0;
}

.home-page .representation .item-right .image-wrap {
  margin-right: 20px;
}

.home-page .representation .item::after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  z-index: -1;
  background-color: #fff;
  border: 1px solid #D8D8D8;
}

.home-page .representation .content {
  display: flex;
  flex-direction: column;
  max-width: 414px;
}

.home-page .representation .title {
  margin-bottom: 20px;
}

.home-page .representation .image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.home-page .representation .image-wrap {
  min-width: 100px;
  height: 100px;
}

.home-page .deal {
  padding: 100px 0;
  position: relative;
}

.home-page .deal .triangle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: -2;
}

.home-page .deal .triangle::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-bottom: solid 410px #F0F1FF;
  border-right: solid 250px transparent;
  border-left: solid 250px transparent;
  z-index: -1;
}

.home-page .deal .triangle span {
  position: relative;
  display: block;
  margin-bottom: auto;
}

.home-page .deal .container {
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 40px;
}

.home-page .deal .hand {
  object-fit: contain;
  width: 50%;
}

.home-page .deal .hand-wrap {
  margin-left: 10px;
  margin-bottom: 40px;
  max-width: 215px;
  max-height: 85px;
  width: 100%;
}

.home-page .deal .hand-left {
  margin-right: -6px;
}

.home-page .deal .hand-right {
  margin-left: -7px;
  margin-bottom: -15px;
}

.home-page .deal .title {
  margin-bottom: 15px;
  text-align: center;
}

.home-page .deal .text {
  margin-bottom: 50px;
  max-width: 440px;
  text-align: center;
}

.home-page .advantages {
  padding: 100px 0 50px;
}

.home-page .advantages .container {
  justify-content: space-between;
}

.home-page .advantages .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 24.591%;
}

.home-page .advantages .content .title {
  margin-bottom: 20px;
}

.home-page .advantages .content .text {
  margin-bottom: 50px;
}

.home-page .advantages .item {
  width: 47.778%;
  display: flex;
  margin-bottom: 50px;
}

.home-page .advantages .item-wrap {
  width: 73.771%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.home-page .advantages .item-icon {
  min-width: 70px;
  height: 70px;
  object-fit: contain;
  margin-right: 10px;
}

.home-page .advantages .item .title {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}

.home-page .advantages .item .text {
  font-size: 14px;
  line-height: 17px;
}

.home-page .voltore {
  padding: 100px 0;
}

.home-page .voltore .container {
  justify-content: space-between;
}

.home-page .voltore .logo {
  width: 252px;
  height: 76px;
  object-fit: contain;
  margin: auto 0;
}

.home-page .voltore .logo-wrap {
  width: 50.41%;
  padding-right: 100px;
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.home-page .voltore .logo-wrap::after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100vw;
  z-index: -1;
  background-color: #4D55FE;
}

.home-page .voltore .content {
  display: flex;
  flex-direction: column;
  width: 42.623%;
}

.home-page .voltore .image {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
  object-fit: contain;
}

.home-page .voltore .title {
  margin-bottom: 20px;
}

.home-page .we-know {
  padding: 100px 0;
}

.home-page .we-know .container {
  justify-content: space-between;
  position: relative;
  padding-top: 65px;
  padding-bottom: 65px;
  height: 100%;
}

.home-page .we-know .container .line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 58.61%;
  width: 1px;
  background-color: #D8D8D8;
}

.home-page .we-know .left {
  width: 52.46%;
  display: flex;
  justify-content: flex-end;
}

.home-page .we-know .left .image {
  max-height: 110px;
  max-width: 110px;
  object-fit: contain;
  margin-right: 50px;
}

.home-page .we-know .right {
  width: 35.246%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.home-page .we-know .right .title {
  max-width: 310px;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}

.home-page .success {
  padding: 100px 0;
}

.home-page .success .container {
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
}

.home-page .success .title {
  margin-bottom: 20px;
}

.home-page .success .text {
  text-align: center;
  max-width: 600px;
  margin-bottom: 60px;
}

.home-page .success .image {
  width: 100%;
}

.home-page .success .image-wrap {
  position: relative;
  display: flex;
}

.home-page .success .image-back.image {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  width: 100%;
}

.home-page .market-tab {
  padding: 40px 0;
}

.home-page .market-tab .container {
  justify-content: space-between;
}

.home-page .market-tab .tabs {
  display: flex;
  flex-direction: column;
}

.home-page .market-tab .tabs-wrap {
  width: 36.886%;
  display: flex;
  flex-direction: column;
  padding: 80px 80px 80px 60px;
  position: relative;
}

.home-page .market-tab .tabs-wrap::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 100vw;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #F9F9F9;
}

.home-page .market-tab .tabs-wrap .title {
  margin-bottom: 20px;
}

.home-page .market-tab .tabs-wrap .text {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 25px;
}

.home-page .market-tab .tab {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  position: relative;
}

.home-page .market-tab .tab-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  transition: color 0.3s ease;
}

.home-page .market-tab .tab-line {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
}

.home-page .market-tab .tab-content {
  width: 51.64%;
}

.home-page .market-tab .tab-content .tab-item {
  display: flex;
}

.home-page .market-tab .tab.active .tab-title {
  color: #4D55FE;
}

.home-page .market-tab .tab.active .tab-line::after {
  position: absolute;
  content: '';
  animation: progressBar 5s linear;
  height: 2px;
  background-color: #4D55FE;
  top: 0;
  left: 0;
  right: 0;
}

.home-page .market-tab .tab.active .tab-line.paused::after {
  animation-play-state: paused;
}

.home-page .market-tab .tab:hover .tab-title {
  color: #6E7191;
}

.home-page .market-tab .tab-item {
  display: none;
  flex-direction: column;
}

.home-page .market-tab .tab-item .image {
  object-fit: contain;
  margin-bottom: 30px;
}

.home-page .transactions {
  padding: 100px 0;
}

.home-page .transactions .container {
  flex-direction: column;
}

.home-page .transactions .title {
  margin-bottom: 50px;
}

.home-page .transactions-slider {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 75px;
}

.home-page .transactions-slider .swiper-scrollbar {
  cursor: pointer;
  bottom: 5px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #F9F9F9;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.home-page .transactions-slider .swiper-scrollbar-drag {
  background-color: #000;
  opacity: 0.2;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.home-page .transactions-slider .swiper-scrollbar:hover {
  height: 6px;
}

.home-page .transactions-slider .swiper-scrollbar:hover .swiper-scrollbar-drag {
  opacity: 1;
}

.home-page .transactions-slider .swiper-scrollbar:active .swiper-scrollbar-drag {
  height: 8px;
  margin: -1px 0 -3px;
  opacity: 1;
}

.home-page .transactions-slider .swiper-button-prev,
.home-page .transactions-slider .swiper-button-next {
  top: initial;
  bottom: 20px;
  width: auto;
  max-width: 38px;
  height: 12px;
  margin: 0;
  transition: opacity 0.3s ease;
  outline: none;
}

.home-page .transactions-slider .swiper-button-prev::after,
.home-page .transactions-slider .swiper-button-next::after {
  display: none;
}

.home-page .transactions-slider .swiper-button-prev {
  left: 0;
}

.home-page .transactions-slider .swiper-button-next {
  transform: rotate(180deg);
  right: 0;
}

.home-page .transactions-slider .swiper-button-disabled {
  opacity: 0;
}

.home-page .caring-tab {
  padding: 100px 0 65px;
}

.home-page .caring-tab .container {
  flex-direction: column;
}

.home-page .caring-tab .title {
  max-width: 640px;
  margin-bottom: 20px;
}

.home-page .caring-tab .text {
  max-width: 640px;
  margin-bottom: 40px;
}

.home-page .caring-tab .tabs {
  width: 52.46%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home-page .caring-tab .tabs-wrap {
  display: flex;
  justify-content: space-between;
}

.home-page .caring-tab .tab {
  cursor: pointer;
  width: 46.875%;
  margin-bottom: 35px;
  display: flex;
}

.home-page .caring-tab .tab:hover .tab-title {
  color: #4D55FE;
}

.home-page .caring-tab .tab.active .tab-topic .line {
  width: 8px;
}

.home-page .caring-tab .tab.active .tab-title {
  color: #4D55FE;
}

.home-page .caring-tab .tab.active .text {
  margin-bottom: 0;
}

.home-page .caring-tab .tab-topic {
  display: flex;
  position: relative;
  padding-left: 20px;
  margin-bottom: auto;
  height: 100%;
}

.home-page .caring-tab .tab-topic .line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 4px;
  background-color: #4D55FE;
  transition: all 0.3s ease;
}

.home-page .caring-tab .tab-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000;
  transition: color 0.3s ease;
  min-height: 50px;
}

.home-page .caring-tab .tab-image {
  max-height: 27px;
  object-fit: contain;
  margin-right: 10px;
}

.home-page .caring-tab .tab-item {
  display: none;
  flex-direction: column;
}

.home-page .caring-tab .tab-item .topic {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 22px;
}

.home-page .caring-tab .tab-content {
  width: 46.722%;
  display: flex;
  padding-left: 40px;
  border-left: 1px solid #D8D8D8;
  margin-bottom: auto;
}

.home-page .caring-tab .tab-content .tab-item {
  display: flex;
}

.home-page .caring-tab .tab-content .tab-item .text {
  margin-bottom: 0;
}

.home-page .contacts {
  padding: 1px 0;
}

.home-page .contacts .container {
  justify-content: space-between;
}

.home-page .contacts-wrap {
  width: 44.06%;
  display: flex;
  position: relative;
  padding: 65px;
  padding-left: 0;
  justify-content: flex-end;
}

.home-page .contacts-wrap::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 100vw;
  right: 0;
  top: 0;
  background-color: #F9F9F9;
  z-index: -1;
}

.home-page .contacts .content {
  width: 55%;
  display: flex;
  flex-direction: column;
  padding-left: 65px;
  margin: auto 0;
}

.home-page .contacts .content .kevin {
  display: flex;
  padding-bottom: 30px;
  max-width: 420px;
  margin-bottom: 20px;
  border-bottom: 1px solid #D8D8D8;
}

.home-page .contacts .content .kevin-photo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-right: 20px;
}

.home-page .contacts .content .kevin-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-page .contacts .content .kevin-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 5px;
}

.home-page .contacts .content .kevin-position {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
}

.home-page .contacts .content .kevin-km {
  max-width: 160px;
  object-fit: contain;
}

.home-page .contacts .content .image {
  max-width: 120px;
  width: 100%;
  object-fit: contain;
  margin-top: 25px;
}

.home-page .contacts .content .title {
  max-width: 490px;
  font-size: 28px;
  line-height: 34px;
}

.not-found-page,
.thanks-page,
.error-page {
  padding-top: 81px;
}

.not-found-page .not-found,
.thanks-page .not-found,
.error-page .not-found {
  padding: 120px 0 160px;
}

.not-found-page .not-found .container,
.thanks-page .not-found .container,
.error-page .not-found .container {
  justify-content: center;
}

.not-found-page .not-found.small-page .content .title,
.thanks-page .not-found.small-page .content .title,
.error-page .not-found.small-page .content .title {
  margin-bottom: 15px;
  font-weight: 500;
}

.not-found-page .not-found .image,
.thanks-page .not-found .image,
.error-page .not-found .image {
  width: 25.782%;
  margin-right: 80px;
  object-fit: contain;
}

.not-found-page .not-found .image-primary.image,
.thanks-page .not-found .image-primary.image,
.error-page .not-found .image-primary.image {
  margin-right: 40px;
}

.not-found-page .not-found .content,
.thanks-page .not-found .content,
.error-page .not-found .content {
  display: flex;
  flex-direction: column;
  width: 46.876%;
}

.not-found-page .not-found .content .subtitle,
.thanks-page .not-found .content .subtitle,
.error-page .not-found .content .subtitle {
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 29px;
  color: #5B5B5B;
}

.not-found-page .not-found .content .title,
.thanks-page .not-found .content .title,
.error-page .not-found .content .title {
  margin-bottom: 60px;
  font-weight: 400;
}

.not-found-page .not-found .content .btn,
.thanks-page .not-found .content .btn,
.error-page .not-found .content .btn {
  margin-right: auto;
}

.terms-page {
  padding-top: 81px;
}

.terms-page .terms {
  padding: 80px 0;
}

.terms-page .terms > .container {
  flex-direction: column;
  max-width: 760px;
}

.terms-page .terms > .container strong,
.terms-page .terms > .container b {
  font-weight: 500;
}

.terms-page .terms > .container a {
  text-decoration: none;
  font-weight: 500;
  color: #4D55FE;
  transition: color 0.3s ease;
}

.terms-page .terms > .container a:hover,
.terms-page .terms > .container a:active,
.terms-page .terms > .container a:focus {
  color: #2026A0;
}

.terms-page .terms > .container a.disabled {
  cursor: auto;
  color: #000;
}

.terms-page .terms > .container h1 {
  margin: 0 0 10px;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  display: flex;
}

.terms-page .terms > .container h2,
.terms-page .terms > .container h3 {
  margin: 30px 0 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.terms-page .terms > .container h3 {
  font-size: 18px;
  line-height: 22px;
}

.terms-page .terms > .container span {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
}

.terms-page .terms > .container p {
  margin: 0 0 10px;
}

.terms-page .terms > .container ul {
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
}

.terms-page .terms > .container ul li {
  position: relative;
  padding-left: 23px;
}

.terms-page .terms > .container ul li::after {
  position: absolute;
  content: '';
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #4D55FE;
}

.terms-page .terms > .container img {
  width: 100%;
  object-fit: contain;
  margin: 30px 0 10px;
}

.terms-page .we-know {
  padding: 100px 0;
}

.terms-page .we-know .container {
  justify-content: space-between;
  position: relative;
  padding-top: 65px;
  padding-bottom: 65px;
  height: 100%;
}

.terms-page .we-know .container .line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 58.61%;
  width: 1px;
  background-color: #D8D8D8;
}

.terms-page .we-know .left {
  width: 52.46%;
  display: flex;
  justify-content: flex-end;
}

.terms-page .we-know .left .image {
  max-height: 110px;
  max-width: 110px;
  object-fit: contain;
  margin-right: 50px;
}

.terms-page .we-know .right {
  width: 35.246%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.terms-page .we-know .right .title {
  max-width: 310px;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}

.blog-page {
  padding-top: 30px;
}

.blog-page .terms {
  padding: 80px 0;
}

.blog-page .terms > .container {
  flex-direction: column;
  max-width: 760px;
}

.blog-page .terms > .container strong,
.blog-page .terms > .container b {
  font-weight: 500;
}

.blog-page .terms > .container a {
  text-decoration: none;
  font-weight: 500;
  color: #4D55FE;
  transition: color 0.3s ease;
}

.blog-page .terms > .container a:hover,
.blog-page .terms > .container a:active,
.blog-page .terms > .container a:focus {
  color: #2026A0;
}

.blog-page .terms > .container a.disabled {
  cursor: auto;
  color: #000;
}

.blog-page .terms > .container h1 {
  margin: 0 0 10px;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  display: flex;
}

.blog-page .terms > .container h2,
.blog-page .terms > .container h3 {
  margin: 30px 0 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.blog-page .terms > .container h3 {
  font-size: 18px;
  line-height: 22px;
}

.blog-page .terms > .container span {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
}

.blog-page .terms > .container p {
  margin: 0 0 10px;
}

.blog-page .terms > .container ul {
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
}

.blog-page .terms > .container ul li {
  position: relative;
  padding-left: 23px;
}

.blog-page .terms > .container ul li::after {
  position: absolute;
  content: '';
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #4D55FE;
}

.blog-page .terms > .container img {
  width: 100%;
  object-fit: contain;
  margin: 30px 0 10px;
}

.blog-page .we-know {
  padding: 100px 0;
}

.blog-page .we-know .container {
  justify-content: space-between;
  position: relative;
  padding-top: 65px;
  padding-bottom: 65px;
  height: 100%;
}

.blog-page .we-know .container .line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 58.61%;
  width: 1px;
  background-color: #D8D8D8;
}

.blog-page .we-know .left {
  width: 52.46%;
  display: flex;
  justify-content: flex-end;
}

.blog-page .we-know .left .image {
  max-height: 110px;
  max-width: 110px;
  object-fit: contain;
  margin-right: 50px;
}

.blog-page .we-know .right {
  width: 35.246%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.blog-page .we-know .right .title {
  max-width: 310px;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}

.blog-page .we-know {
  padding: 110px 0 80px;
}

@keyframes progressBar {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@media (max-width: 1656px) {
  .container {
    padding: 0 30px;
  }
}

@media (max-width: 1200px) {
  .home-page .representation .container .rectangle {
    width: 500px;
    height: 125%;
    height: calc(100% + 100px);
  }

  .home-page .representation .container {
    flex-direction: column;
  }

  .home-page .representation .item-left .image-wrap {
    margin-left: 40px;
  }

  .home-page .representation .item-left .text,
  .home-page .representation .item-left .title {
    text-align: left;
  }

  .home-page .representation .item-left .title {
    align-self: flex-start;
  }

  .home-page .representation .item-left {
    margin-bottom: 30px;
  }

  .home-page .representation .item-right .image-wrap {
    margin-right: 40px;
  }

  .home-page .representation .item-right {
    align-self: flex-end;
  }

  .home-page .representation .item {
    width: 70%;
    justify-content: flex-end;
  }

  .home-page .representation .content {
    max-width: 470px;
  }

  .home-page .advantages .content {
    width: 30%;
  }

  .home-page .advantages .item-wrap {
    width: 67%;
  }

  .home-page .we-know .left {
    width: 55.46%;
  }

  .home-page .market-tab .tabs-wrap {
    width: 40%;
    padding: 80px 50px 80px 30px;
  }

  .not-found-page .not-found .image,
  .thanks-page .not-found .image,
  .error-page .not-found .image {
    margin-right: 60px;
  }

  .terms-page .we-know .left {
    width: 55.46%;
  }

  .blog-page .we-know .left {
    width: 55.46%;
  }
}

@media (max-width: 992px) {
  .navbar-menu {
    padding: 65px 15px 60px;
  }

  .footer .link-item {
    margin: 0 10px;
  }

  .home-page .header .content {
    padding-left: 35px;
  }

  .home-page .header {
    padding: 80px 0;
  }

  .home-page .partner .container {
    flex-direction: column;
  }

  .home-page .partner .content {
    margin-bottom: 40px;
    width: 84.746%;
  }

  .home-page .partner .image-wrap {
    width: 84.746%;
  }

  .home-page .partner .text {
    max-width: none;
  }

  .home-page .representation .item {
    width: 80.51%;
  }

  .home-page .representation {
    padding: 130px 0;
  }

  .home-page .advantages .content {
    width: 36.442%;
  }

  .home-page .advantages .item-wrap {
    width: 60.735%;
  }

  .home-page .advantages .item {
    width: 100%;
  }

  .home-page .voltore .logo-wrap {
    width: 48.588%;
    padding-right: 84px;
  }

  .home-page .voltore .logo {
    width: 170px;
    height: 51px;
  }

  .home-page .voltore .content {
    width: 48.588%;
  }

  .home-page .voltore .image {
    width: 90px;
    height: 90px;
    margin-bottom: 10px;
  }

  .home-page .voltore .title {
    margin-bottom: 15px;
  }

  .home-page .we-know .container .line {
    left: 57.91%;
  }

  .home-page .we-know .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .home-page .we-know .left .image {
    max-height: 90px;
    max-width: 90px;
    margin-right: 30px;
  }

  .home-page .we-know .left .title {
    font-size: 28px;
    line-height: 34px;
  }

  .home-page .we-know .right .title {
    margin-bottom: 15px;
  }

  .home-page .we-know .right {
    width: 36.442%;
  }

  .home-page .market-tab .tabs-wrap {
    width: 45%;
    padding: 50px 50px 50px 0;
  }

  .home-page .caring-tab .tabs {
    width: 100%;
  }

  .home-page .caring-tab .tab-item .text {
    margin-top: 10px;
  }

  .home-page .caring-tab .tab-item {
    width: 100%;
    margin-top: 35px;
  }

  .home-page .caring-tab .tab-content {
    display: none;
  }

  .home-page .caring-tab .tab {
    flex-wrap: wrap;
  }

  .home-page .caring-tab .tab {
    width: 48.589%;
  }

  .home-page .contacts .content {
    padding-left: 20px;
  }

  .not-found-page .not-found .container,
  .thanks-page .not-found .container,
  .error-page .not-found .container {
    justify-content: space-between;
  }

  .not-found-page .not-found.small-page .container,
  .thanks-page .not-found.small-page .container,
  .error-page .not-found.small-page .container {
    justify-content: center;
  }

  .not-found-page .not-found .image-primary.image,
  .thanks-page .not-found .image-primary.image,
  .error-page .not-found .image-primary.image {
    margin-right: 40px;
  }

  .not-found-page .not-found .image,
  .thanks-page .not-found .image,
  .error-page .not-found .image {
    margin-right: 0;
    width: 35%;
  }

  .not-found-page .not-found .content,
  .thanks-page .not-found .content,
  .error-page .not-found .content {
    width: 60%;
  }

  .terms-page .we-know .container .line {
    left: 57.91%;
  }

  .terms-page .we-know .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .terms-page .we-know .left .image {
    max-height: 90px;
    max-width: 90px;
    margin-right: 30px;
  }

  .terms-page .we-know .left .title {
    font-size: 28px;
    line-height: 34px;
  }

  .terms-page .we-know .right .title {
    margin-bottom: 15px;
  }

  .terms-page .we-know .right {
    width: 36.442%;
  }

  .blog-page .we-know .container .line {
    left: 57.91%;
  }

  .blog-page .we-know .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .blog-page .we-know .left .image {
    max-height: 90px;
    max-width: 90px;
    margin-right: 30px;
  }

  .blog-page .we-know .left .title {
    font-size: 28px;
    line-height: 34px;
  }

  .blog-page .we-know .right .title {
    margin-bottom: 15px;
  }

  .blog-page .we-know .right {
    width: 36.442%;
  }
}

@media (max-width: 850px) {
  .home-page .contacts-wrap {
    padding-right: 30px;
  }
}

@media (max-width: 768px) {
  .navbar-menu {
    padding: 35px 15px 20px;
  }

  .navbar .menu-link {
    font-size: 36px;
    line-height: 44px;
  }

  .navbar .motto {
    font-size: 14px;
    line-height: 17px;
    max-width: 230px;
  }

  .navbar .logo-wrap {
    max-width: 160px;
    max-height: 30px;
  }

  .navbar .burger {
    margin-left: 15px;
  }

  .footer .main {
    padding: 40px 0 15px;
  }

  .title {
    font-size: 26px;
    line-height: 32px;
  }

  .card .content .price-wrap {
    font-size: 22px;
    line-height: 27px;
  }

  .contact-form-wrap .title {
    margin-bottom: 15px;
  }

  .contact-form-wrap .text {
    font-size: 14px;
    line-height: 18px;
  }

  .home-page .footer {
    margin-top: 20px;
  }

  .home-page .header::before {
    display: none;
  }

  .home-page .header::after {
    display: none;
  }

  .home-page .header .image-wrap {
    position: relative;
    top: initial;
    right: initial;
    transform: none;
    z-index: 0;
    background-color: #fff;
    padding: 0 0 25px;
    border-bottom: 1px solid #A4A4A4;
  }

  .home-page .header .content {
    width: 100%;
    padding: 55px 0;
  }

  .home-page .header .subtitle {
    font-size: 12px;
    line-height: 15px;
    max-width: none;
  }

  .home-page .header .title {
    max-width: none;
    margin-bottom: 15px;
  }

  .home-page .header .text {
    font-size: 14px;
    line-height: 18px;
    max-width: 420px;
  }

  .home-page .header {
    padding-top: 90px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column-reverse;
  }

  .home-page .partner .title {
    margin-bottom: 15px;
  }

  .home-page .partner .partner-logo {
    margin-top: 40px;
    max-width: 120px;
    max-height: 30px;
  }

  .home-page .partner {
    padding: 60px 0;
  }

  .home-page .representation .container .rectangle span:nth-of-type(1) {
    width: 100%;
  }

  .home-page .representation .container .rectangle span:nth-of-type(4) {
    width: 100%;
  }

  .home-page .representation .container .rectangle span:nth-of-type(2) {
    height: 100%;
  }

  .home-page .representation .container .rectangle span:nth-of-type(3) {
    height: 100%;
  }

  .home-page .representation .item {
    width: 82%;
  }

  .home-page .representation .title {
    margin-bottom: 15px;
  }

  .home-page .representation .text {
    font-size: 14px;
    line-height: 18px;
  }

  .home-page .representation .image-wrap {
    min-width: 80px;
    height: 80px;
  }

  .home-page .representation {
    padding: 110px 0;
  }

  .home-page .deal .triangle::after {
    border-bottom: solid 340px #F0F1FF;
    border-right: solid 210px transparent;
    border-left: solid 210px transparent;
  }

  .home-page .deal .triangle span {
    border-bottom: solid 230px #fff;
    border-right: solid 140px transparent;
    border-left: solid 140px transparent;
    margin-top: 155px;
  }

  .home-page .deal .hand-wrap {
    max-width: 160px;
    max-height: 72px;
  }

  .home-page .deal .title {
    margin-bottom: 10px;
  }

  .home-page .deal .text {
    margin-bottom: 40px;
    max-width: 420px;
    font-size: 14px;
    line-height: 18px;
  }

  .home-page .deal {
    padding: 60px 0;
  }

  .home-page .advantages .content .title {
    margin-bottom: 15px;
  }

  .home-page .advantages .content .text {
    font-size: 14px;
    line-height: 18px;
  }

  .home-page .advantages .item-icon {
    margin-right: 20px;
    min-width: 60px;
    height: 60px;
  }

  .home-page .advantages .item {
    margin-bottom: 40px;
  }

  .home-page .advantages {
    padding: 60px 0 20px;
  }

  .home-page .voltore .text {
    font-size: 14px;
    line-height: 18px;
  }

  .home-page .voltore {
    padding: 60px 0;
  }

  .home-page .success .title {
    margin-bottom: 10px;
  }

  .home-page .success .text {
    font-size: 14px;
    line-height: 18px;
  }

  .home-page .success .image-back.image {
    bottom: 0;
  }

  .home-page .success {
    padding: 60px 0;
  }

  .home-page .market-tab .tabs-wrap::after {
    display: none;
  }

  .home-page .market-tab .tabs-wrap .title {
    margin-bottom: 15px;
  }

  .home-page .market-tab .tabs-wrap .text {
    margin-bottom: 5px;
  }

  .home-page .market-tab .tabs-wrap {
    width: 100%;
    padding: 0;
  }

  .home-page .market-tab .tab::after {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    right: 5px;
    top: 28px;
    transform: rotate(-45deg);
    transition: transform 0.3s ease;
    z-index: -1;
  }

  .home-page .market-tab .tab-title {
    position: relative;
  }

  .home-page .market-tab .tab-title::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.15);
    bottom: -25px;
    left: 0;
    right: 0;
  }

  .home-page .market-tab .tab-line {
    display: none;
  }

  .home-page .market-tab .tab-content {
    display: none;
  }

  .home-page .market-tab .tab.active::after {
    transform: rotate(135deg);
  }

  .home-page .market-tab .tab.active .tab-title {
    color: #000;
  }

  .home-page .market-tab .tab.active .tab-line::after {
    display: none;
  }

  .home-page .market-tab .tab.active {
    padding-bottom: 15px;
  }

  .home-page .market-tab .tab:hover .tab-title {
    color: #000;
  }

  .home-page .market-tab .tab-item .text {
    margin-bottom: 0;
  }

  .home-page .market-tab .tab-item {
    margin-top: 55px;
    max-width: 600px;
    display: flex;
  }

  .home-page .transactions .title {
    margin-bottom: 40px;
  }

  .home-page .transactions-slider {
    overflow: visible;
  }

  .home-page .transactions-slider::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100vw;
    top: 0;
    right: 100%;
    background-color: #fff;
    z-index: 3;
  }

  .home-page .transactions {
    padding: 60px 0;
  }

  .home-page .caring-tab .title {
    max-width: none;
    margin-bottom: 15px;
  }

  .home-page .caring-tab .text {
    font-size: 14px;
    line-height: 18px;
  }

  .home-page .caring-tab .tab-topic .line {
    height: 100%;
  }

  .home-page .caring-tab .tab-topic {
    height: initial;
  }

  .home-page .caring-tab .tab-item .topic {
    font-size: 18px;
  }

  .home-page .caring-tab {
    padding: 60px 0 25px;
  }

  .home-page .contacts .container {
    flex-direction: column;
  }

  .home-page .contacts-wrap::after {
    right: initial;
    left: 50%;
    transform: translateX(-50%);
  }

  .home-page .contacts-wrap {
    width: 100%;
    padding: 60px 0 80px;
    justify-content: center;
    order: 2;
  }

  .home-page .contacts .content .kevin {
    border-bottom: none;
  }

  .home-page .contacts .content .image {
    display: none;
  }

  .home-page .contacts .content .title {
    display: none;
  }

  .home-page .contacts .content {
    margin: 0;
    width: 100%;
    padding: 0;
    align-items: center;
    order: 1;
  }

  .home-page .contacts {
    padding: 40px 0;
  }

  .not-found-page .not-found .container,
  .thanks-page .not-found .container,
  .error-page .not-found .container {
    flex-direction: column;
  }

  .not-found-page .not-found.small-page .container,
  .thanks-page .not-found.small-page .container,
  .error-page .not-found.small-page .container {
    flex-direction: row;
  }

  .not-found-page .not-found.small-page .content .title,
  .thanks-page .not-found.small-page .content .title,
  .error-page .not-found.small-page .content .title {
    font-size: 36px;
    line-height: 44px;
  }

  .not-found-page .not-found.small-page .content,
  .thanks-page .not-found.small-page .content,
  .error-page .not-found.small-page .content {
    width: 55%;
    width: fit-content;
  }

  .not-found-page .not-found .image-primary.image,
  .thanks-page .not-found .image-primary.image,
  .error-page .not-found .image-primary.image {
    margin-bottom: 0;
    width: 49.5%;
  }

  .not-found-page .not-found .image,
  .thanks-page .not-found .image,
  .error-page .not-found .image {
    margin-bottom: 40px;
    width: 49.5%;
  }

  .not-found-page .not-found .content .subtitle,
  .thanks-page .not-found .content .subtitle,
  .error-page .not-found .content .subtitle {
    font-size: 22px;
    line-height: 25px;
  }

  .not-found-page .not-found .content,
  .thanks-page .not-found .content,
  .error-page .not-found .content {
    width: 100%;
  }

  .not-found-page .not-found,
  .thanks-page .not-found,
  .error-page .not-found {
    padding: 80px 0 100px;
  }

  .terms-page .terms > .container h1 {
    font-size: 26px;
    line-height: 32px;
  }

  .terms-page .terms {
    padding: 40px 0 50px;
  }

  .blog-page .terms > .container h1 {
    font-size: 26px;
    line-height: 32px;
  }

  .blog-page .terms {
    padding: 40px 0 50px;
  }
}

@media (max-width: 767px) {
  .home-page .we-know .container .line {
    display: none;
  }

  .home-page .we-know .container {
    flex-direction: column;
  }

  .home-page .we-know .left .image {
    margin-right: 20px;
  }

  .home-page .we-know .left {
    width: 100%;
    justify-content: center;
    padding-bottom: 25px;
    border-bottom: 1px solid #D8D8D8;
  }

  .home-page .we-know .right .title {
    text-align: center;
    margin-bottom: 20px;
  }

  .home-page .we-know .right {
    width: 100%;
    padding-top: 25px;
    align-items: center;
  }

  .home-page .we-know {
    padding: 60px 0;
  }

  .terms-page .we-know .container .line {
    display: none;
  }

  .terms-page .we-know .container {
    flex-direction: column;
  }

  .terms-page .we-know .left .image {
    margin-right: 20px;
  }

  .terms-page .we-know .left {
    width: 100%;
    justify-content: center;
    padding-bottom: 25px;
    border-bottom: 1px solid #D8D8D8;
  }

  .terms-page .we-know .right .title {
    text-align: center;
    margin-bottom: 20px;
  }

  .terms-page .we-know .right {
    width: 100%;
    padding-top: 25px;
    align-items: center;
  }

  .terms-page .we-know {
    padding: 60px 0;
  }

  .blog-page .we-know .container .line {
    display: none;
  }

  .blog-page .we-know .container {
    flex-direction: column;
  }

  .blog-page .we-know .left .image {
    margin-right: 20px;
  }

  .blog-page .we-know .left {
    width: 100%;
    justify-content: center;
    padding-bottom: 25px;
    border-bottom: 1px solid #D8D8D8;
  }

  .blog-page .we-know .right .title {
    text-align: center;
    margin-bottom: 20px;
  }

  .blog-page .we-know .right {
    width: 100%;
    padding-top: 25px;
    align-items: center;
  }

  .blog-page .we-know {
    padding: 60px 0;
  }
}

@media (max-width: 715px) {
  .footer .main-left {
    width: 100%;
  }

  .footer .main-right {
    margin-top: 25px;
    width: 100%;
    justify-content: flex-start;
  }

  .footer .main {
    flex-direction: column;
    padding: 40px 0;
  }

  .footer .bottom .copyright {
    margin: 0 auto;
    order: 2;
    font-size: 8px;
    line-height: 10px;
  }

  .footer .bottom-links a:first-of-type {
    padding-left: 0;
  }

  .footer .bottom-links {
    margin: 0 auto 15px;
    order: 1;
  }

  .footer .bottom {
    flex-direction: column;
    justify-content: center;
    padding: 15px 0 20px;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 0 15px;
  }

  .footer .main-right {
    margin-top: 15px;
  }

  .footer .main {
    padding: 30px 0;
  }

  .footer .logo-wrap {
    margin-bottom: 25px;
    max-width: 115px;
    max-height: 13px;
  }

  .footer .link {
    opacity: 1;
  }

  .title {
    font-size: 24px;
    line-height: 30px;
  }

  .text {
    font-size: 14px;
    line-height: 18px;
  }

  .contact-form-wrap .title {
    font-size: 20px;
    line-height: 24px;
  }

  .contact-form-wrap .text {
    line-height: 20px;
    margin-bottom: 20px;
  }

  .small-page .content .text {
    text-align: center;
  }

  .home-page .header .content {
    padding-bottom: 30px;
  }

  .home-page .header .btn {
    margin-right: 0;
    width: 100%;
    justify-content: center;
  }

  .home-page .header {
    padding-top: 80px;
  }

  .home-page .partner .content {
    width: 100%;
  }

  .home-page .partner .image-wrap {
    width: 100%;
  }

  .home-page .partner .text {
    font-size: 14px;
    line-height: 20px;
  }

  .home-page .partner {
    padding: 40px 0;
  }

  .home-page .representation .container .rectangle {
    width: 85%;
    height: 120%;
    height: calc(100% + 60px);
  }

  .home-page .representation .item-left .image-wrap {
    margin-left: 10px;
  }

  .home-page .representation .item-right .image-wrap {
    margin-right: 10px;
  }

  .home-page .representation .item {
    padding: 20px 10px;
    width: 100%;
  }

  .home-page .representation .title {
    margin-bottom: 10px;
  }

  .home-page .representation .image-wrap {
    min-width: 60px;
    height: 60px;
  }

  .home-page .representation {
    padding: 70px 0;
  }

  .home-page .deal .triangle::after {
    border-bottom: solid 330px #F0F1FF;
    border-right: solid 180px transparent;
    border-left: solid 180px transparent;
  }

  .home-page .deal .triangle span {
    border-bottom: solid 230px #fff;
    border-right: solid 120px transparent;
    border-left: solid 120px transparent;
    margin-top: 110px;
  }

  .home-page .deal .container {
    padding-top: 45px;
    padding-bottom: 30px;
  }

  .home-page .deal .hand-wrap {
    max-width: 140px;
    max-height: 62px;
    margin-bottom: 30px;
  }

  .home-page .deal .hand-right {
    margin-bottom: -12px;
  }

  .home-page .deal .title {
    font-size: 20px;
    line-height: 24px;
  }

  .home-page .deal .text {
    margin-bottom: 30px;
    max-width: 320px;
  }

  .home-page .deal {
    padding: 40px 0;
  }

  .home-page .advantages .container {
    flex-direction: column;
  }

  .home-page .advantages .content .title {
    font-size: 20px;
    line-height: 24px;
  }

  .home-page .advantages .content .text {
    line-height: 20px;
    margin-bottom: 30px;
  }

  .home-page .advantages .content .btn {
    display: none;
  }

  .home-page .advantages .content {
    width: 100%;
  }

  .home-page .advantages .item-wrap {
    width: 100%;
  }

  .home-page .advantages {
    padding: 40px 0 0;
  }

  .home-page .voltore .container {
    flex-direction: column;
  }

  .home-page .voltore .logo-wrap {
    width: 185px;
    padding: 15px 30px 15px 0;
    margin-top: 25px;
    order: 2;
  }

  .home-page .voltore .logo {
    width: 126px;
    height: 38px;
  }

  .home-page .voltore .content {
    width: 100%;
    order: 1;
  }

  .home-page .voltore .title {
    font-size: 20px;
    line-height: 24px;
  }

  .home-page .voltore .text {
    line-height: 20px;
  }

  .home-page .voltore {
    padding: 40px 0;
  }

  .home-page .we-know .right .btn {
    width: 100%;
    justify-content: center;
  }

  .home-page .we-know {
    padding: 40px 0;
  }

  .home-page .success .container {
    align-items: flex-start;
  }

  .home-page .success .title {
    font-size: 20px;
    line-height: 24px;
  }

  .home-page .success .text {
    text-align: left;
    line-height: 20px;
    margin-bottom: 30px;
  }

  .home-page .success .image {
    min-width: 483px;
  }

  .home-page .success {
    padding: 40px 0;
  }

  .home-page .market-tab .tabs-wrap .title {
    font-size: 22px;
    line-height: 27px;
  }

  .home-page .market-tab .tabs-wrap .text {
    line-height: 20px;
  }

  .home-page .market-tab .tab-title {
    font-size: 18px;
    line-height: 21px;
  }

  .home-page .market-tab .tab-item .image {
    margin-bottom: 20px;
  }

  .home-page .market-tab .tab-item {
    margin-top: 45px;
  }

  .home-page .transactions .title {
    margin-bottom: 30px;
  }

  .home-page .transactions {
    padding: 40px 0;
  }

  .home-page .caring-tab .title {
    font-size: 20px;
    line-height: 24px;
  }

  .home-page .caring-tab .text {
    line-height: 20px;
    margin-bottom: 30px;
  }

  .home-page .caring-tab .tab {
    width: 100%;
  }

  .home-page .caring-tab {
    padding: 40px 0 5px;
  }

  .home-page .contacts-wrap {
    padding: 50px 0 70px;
  }

  .home-page .contacts .content .kevin-photo {
    max-width: 102px;
    max-height: 102px;
    width: 100%;
    height: 100%;
  }

  .home-page .contacts .content .kevin-name {
    font-size: 22px;
    line-height: 27px;
  }

  .home-page .contacts .content .kevin-position {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px;
  }

  .home-page .contacts .content .kevin-km {
    max-width: 136px;
  }

  .not-found-page .not-found.small-page .container,
  .thanks-page .not-found.small-page .container,
  .error-page .not-found.small-page .container {
    flex-direction: column;
    align-items: center;
  }

  .not-found-page .not-found.small-page .content .title,
  .thanks-page .not-found.small-page .content .title,
  .error-page .not-found.small-page .content .title {
    text-align: center;
  }

  .not-found-page .not-found.small-page .content,
  .thanks-page .not-found.small-page .content,
  .error-page .not-found.small-page .content {
    align-items: center;
  }

  .not-found-page .not-found .image-primary.image,
  .thanks-page .not-found .image-primary.image,
  .error-page .not-found .image-primary.image {
    margin-bottom: 40px;
    margin-right: 0;
  }

  .not-found-page .not-found .content .subtitle,
  .thanks-page .not-found .content .subtitle,
  .error-page .not-found .content .subtitle {
    font-size: 18px;
    line-height: 22px;
  }

  .not-found-page .not-found .content .btn,
  .thanks-page .not-found .content .btn,
  .error-page .not-found .content .btn {
    margin-right: 0;
    justify-content: center;
  }

  .not-found-page .not-found,
  .thanks-page .not-found,
  .error-page .not-found {
    padding: 20px 0 60px;
  }

  .terms-page .terms > .container h1 {
    font-size: 26px;
    line-height: 32px;
  }

  .terms-page .terms > .container h2,
  .terms-page .terms > .container h3 {
    margin: 25px 0 20px;
    font-size: 22px;
    line-height: 27px;
  }

  .terms-page .terms > .container h3 {
    font-size: 16px;
    line-height: 20px;
  }

  .terms-page .terms > .container span {
    font-size: 18px;
    line-height: 21px;
  }

  .terms-page .terms > .container p {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 20px;
  }

  .terms-page .terms > .container ul {
    margin-top: 5px;
  }

  .terms-page .terms {
    padding: 20px 0 30px;
  }

  .terms-page .we-know .right .btn {
    width: 100%;
    justify-content: center;
  }

  .terms-page .we-know {
    padding: 40px 0;
  }

  .blog-page .terms > .container h1 {
    font-size: 26px;
    line-height: 32px;
  }

  .blog-page .terms > .container h2,
  .blog-page .terms > .container h3 {
    margin: 25px 0 20px;
    font-size: 22px;
    line-height: 27px;
  }

  .blog-page .terms > .container h3 {
    font-size: 16px;
    line-height: 20px;
  }

  .blog-page .terms > .container span {
    font-size: 18px;
    line-height: 21px;
  }

  .blog-page .terms > .container p {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 20px;
  }

  .blog-page .terms > .container ul {
    margin-top: 5px;
  }

  .blog-page .terms {
    padding: 20px 0 30px;
  }

  .blog-page .we-know .right .btn {
    width: 100%;
    justify-content: center;
  }

  .blog-page .we-know {
    padding: 40px 0;
  }

  .blog-page {
    padding-top: 50px;
  }
}

@media (max-width: 450px) {
  .footer .link-item {
    margin: 0 5px;
  }

  .footer .link {
    padding: 10px 5px;
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .home-page .deal .triangle::after {
    border-bottom: solid 310px #F0F1FF;
    border-right: solid 180px transparent;
    border-left: solid 180px transparent;
    top: 215px;
  }

  .home-page .advantages .item-icon {
    margin-right: 10px;
  }

  .home-page .we-know .left .image {
    max-height: 70px;
    max-width: 70px;
    margin-right: 15px;
  }

  .home-page .we-know .left .title {
    font-size: 24px;
    line-height: 30px;
  }

  .terms-page .we-know .left .image {
    max-height: 70px;
    max-width: 70px;
    margin-right: 15px;
  }

  .terms-page .we-know .left .title {
    font-size: 24px;
    line-height: 30px;
  }

  .blog-page .we-know .left .image {
    max-height: 70px;
    max-width: 70px;
    margin-right: 15px;
  }

  .blog-page .we-know .left .title {
    font-size: 24px;
    line-height: 30px;
  }
}