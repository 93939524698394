.success {
  padding: 100px 0;

  .container {
    flex-direction: column;
    align-items: center;
    padding: 0 15px;

    @include sm {
      align-items: flex-start;
    }
  }

  .title {
    margin-bottom: 20px;

    @include md {
      margin-bottom: 10px;
    }

    @include sm {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .text {
    text-align: center;
    max-width: 600px;
    margin-bottom: 60px;

    @include md {
      font-size: 14px;
      line-height: 18px;
    }

    @include sm {
      text-align: left;
      line-height: 20px;
      margin-bottom: 30px;
    }
  }

  .image {
    width: 100%;

    &-wrap {
      position: relative;
      display: flex;
    }

    &-back.image {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      width: 100%;

      @include md {
        bottom: 0;
      }
    }

    @include sm {
      min-width: 483px;
    }
  }

  @include md {
    padding: 60px 0;
  }

  @include sm {
    padding: 40px 0;
  }
}
