.title {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  margin: 0;

  @include md {
    font-size: 26px;
    line-height: 32px;
  }

  @include sm {
    font-size: 24px;
    line-height: 30px;
  }
}
