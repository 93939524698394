.deal {
  padding: 100px 0;
  position: relative;

  .triangle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: -2;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-bottom: solid 410px $light-purple;
      border-right: solid 250px transparent;
      border-left: solid 250px transparent;
      z-index: -1;

      @include md {
        //top: 52%;
        border-bottom: solid 340px $light-purple;
        border-right: solid 210px transparent;
        border-left: solid 210px transparent;

      }

      @include sm {
        //top: 51%;
        border-bottom: solid 330px $light-purple;
        border-right: solid 180px transparent;
        border-left: solid 180px transparent;
      }

      @include rwd(400) {
        border-bottom: solid 310px $light-purple;
        border-right: solid 180px transparent;
        border-left: solid 180px transparent;
        top: 215px;
      }

      //@include rwd(400) {
      //  border-bottom: solid 330px $light-purple;
      //  border-right: solid 150px transparent;
      //  border-left: solid 150px transparent;
      //}
    }

    span {
      position: relative;
      display: block;
      margin-bottom: auto;

      @include md {
        border-bottom: solid 230px $white;
        border-right: solid 140px transparent;
        border-left: solid 140px transparent;
        margin-top: 155px;
      }

      @include sm {
        border-bottom: solid 230px $white;
        border-right: solid 120px transparent;
        border-left: solid 120px transparent;
        margin-top: 110px;
      }

      //@include rwd(400) {
      //  border-bottom: solid 240px $white;
      //  border-right: solid 100px transparent;
      //  border-left: solid 100px transparent;
      //  margin-top: 110px;
      //}
    }
  }

  .container {
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 40px;

    @include sm {
      padding-top: 45px;
      padding-bottom: 30px;
    }
  }

  .hand {
    object-fit: contain;
    width: 50%;

    &-wrap {
      margin-left: 10px;
      margin-bottom: 40px;
      max-width: 215px;
      max-height: 85px;
      width: 100%;

      @include md {
        max-width: 160px;
        max-height: 72px;
      }

      @include sm {
        max-width: 140px;
        max-height: 62px;
        margin-bottom: 30px;
      }
    }

    &-left {
      margin-right: -6px;
    }

    &-right {
      margin-left: -7px;
      margin-bottom: -15px;

      @include sm {
        margin-bottom: -12px;
      }
    }
  }

  .title {
    margin-bottom: 15px;
    text-align: center;

    @include md {
      margin-bottom: 10px;
    }

    @include sm {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .text {
    margin-bottom: 50px;
    max-width: 440px;
    text-align: center;

    @include md {
      margin-bottom: 40px;
      max-width: 420px;
      font-size: 14px;
      line-height: 18px;
    }

    @include sm {
      margin-bottom: 30px;
      max-width: 320px;
    }
  }

  @include md {
    padding: 60px 0;
  }

  @include sm {
    padding: 40px 0;
  }
}
