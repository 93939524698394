.we-know {
  padding: 100px 0;

  .container {
    justify-content: space-between;
    position: relative;
    padding-top: 65px;
    padding-bottom: 65px;
    height: 100%;

    .line {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 58.61%;
      width: 1px;
      //height: 100%;
      background-color: $light-grey;

      @include lg {
        left: 57.91%;
      }

      @include rwd(767)  {
        display: none;
      }
    }

    @include lg {
      padding-top: 0;
      padding-bottom: 0;
    }

    @include rwd(767)  {
      flex-direction: column;
    }
  }

  .left {
    width: 52.46%;
    display: flex;
    justify-content: flex-end;

    .image {
      max-height: 110px;
      max-width: 110px;
      object-fit: contain;
      margin-right: 50px;

      @include lg {
        max-height: 90px;
        max-width: 90px;
        margin-right: 30px;
      }

      @include rwd(767)  {
        margin-right: 20px;
      }

      @include rwd(400) {
        max-height: 70px;
        max-width: 70px;
        margin-right: 15px;
      }
    }

    .title {
      @include lg {
        font-size: 28px;
        line-height: 34px;
      }

      @include rwd(400) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    @include xl {
      width: 55.46%;
    }

    @include rwd(767)  {
      width: 100%;
      justify-content: center;
      padding-bottom: 25px;
      border-bottom: 1px solid $light-grey;
    }
  }

  .right {
    width: 35.246%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
      max-width: 310px;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 20px;

      @include lg {
        margin-bottom: 15px;
      }

      @include rwd(767)  {
        text-align: center;
        margin-bottom: 20px;
      }
    }

    .btn {
      @include sm {
        width: 100%;
        justify-content: center;
      }
    }

    @include lg {
      width: 36.442%;
    }

    @include rwd(767)  {
      width: 100%;
      padding-top: 25px;
      align-items: center;
    }
  }

  @include rwd(767)  {
    padding: 60px 0;
  }

  @include sm {
    padding: 40px 0;
  }
}
