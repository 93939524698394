.card {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: $text;
  width: 260px;

  &:hover {
    .image {
      filter: grayscale(0);
    }
  }

  .image {
    filter: grayscale(100%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;

    &-wrap {
      width: 260px;
      height: 268px;
      display: flex;
      flex-direction: column;

      .line {
        width: 100%;
        height: 8px;
        background-color: $primary;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px;

    .name {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: rgba($text, 0.5);
    }

    .price-wrap {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      margin-top: 15px;

      @include md {
        font-size: 22px;
        line-height: 27px;
      }
    }

    .cap-rate-wrap, .noi-wrap {
      margin-top: 5px;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
