.advantages {
  padding: 100px 0 50px;

  .container {
    justify-content: space-between;

    @include sm {
      flex-direction: column;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 24.591%;

    .title {
      margin-bottom: 20px;

      @include md {
        margin-bottom: 15px;
      }

      @include sm {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .text {
      margin-bottom: 50px;

      @include md {
        font-size: 14px;
        line-height: 18px;
      }

      @include sm {
        line-height: 20px;
        margin-bottom: 30px;
      }
    }

    .btn {
      @include sm {
        display: none;
      }
    }

    @include xl {
      width: 30%;
    }

    @include lg {
      width: 36.442%;
    }

    @include sm {
      width: 100%;
    }
  }

  .item {
    width: 47.778%;
    display: flex;
    margin-bottom: 50px;

    &-wrap {
      width: 73.771%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include xl {
        width: 67%;
      }

      @include lg {
        width: 60.735%;
      }

      @include sm {
        width: 100%;
      }
    }

    &-icon {
      min-width: 70px;
      height: 70px;
      object-fit: contain;
      margin-right: 10px;

      @include md {
        margin-right: 20px;
        min-width: 60px;
        height: 60px;
      }

      @include rwd(400) {
        margin-right: 10px;
      }
    }

    .title {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 10px;
    }

    .text {
      font-size: 14px;
      line-height: 17px;
    }

    @include lg {
      width: 100%;
    }

    @include md {
      margin-bottom: 40px;
    }
  }

  @include md {
    padding: 60px 0 20px;
  }

  @include sm {
    padding: 40px 0 0;
  }
}
