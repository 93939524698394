.transactions {
  padding: 100px 0;

  .container {
    flex-direction: column;
  }

  .title {
    margin-bottom: 50px;

    @include md {
      margin-bottom: 40px;
    }

    @include sm {
      margin-bottom: 30px;
    }
  }

  &-slider {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 75px;

    .swiper {
      &-scrollbar {
        cursor: pointer;
        bottom: 5px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $milky;
        border-radius: 5px;
        transition: all 0.3s ease;

        &-drag {
          background-color: $text;
          opacity: 0.2;
          transition: all 0.3s ease;
          border-radius: 5px;
        }

        &:hover {
          height: 6px;

          .swiper-scrollbar-drag {
            opacity: 1;
          }
        }

        &:active {
          .swiper-scrollbar-drag {
            height: 8px;
            margin: -1px 0 -3px;
            opacity: 1;
          }
        }
      }

      &-button-prev,
      &-button-next {
        top: initial;
        bottom: 20px;
        width: auto;
        max-width: 38px;
        height: 12px;
        margin: 0;
        transition: opacity 0.3s ease;
        outline: none;

        &::after {
          display: none;
        }
      }

      &-button-prev {
        left: 0;
      }

      &-button-next {
        transform: rotate(180deg);
        right: 0;
      }

      &-button-disabled {
        opacity: 0;
      }
    }

    @include md {
      overflow: visible;

      &::after {
        position: absolute;
        content: '';
        height: 100%;
        width: 100vw;
        top: 0;
        right: 100%;
        background-color: $white;
        z-index: 3;
      }
    }
  }

  @include md {
    padding: 60px 0;
  }

  @include sm {
    padding: 40px 0;
  }
}
