.market-tab {
  padding: 40px 0;

  .container {
    justify-content: space-between;
  }

  .tabs {
    display: flex;
    flex-direction: column;

    &-wrap {
      width: 36.886%;
      display: flex;
      flex-direction: column;
      padding: 80px 80px 80px 60px;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        height: 100%;
        width: 100vw;
        top: 0;
        right: 0;
        z-index: -1;
        background-color: $milky;

        @include md {
          display: none;
        }
      }

      .title {
        margin-bottom: 20px;

        @include md {
          margin-bottom: 15px;
        }

        @include sm {
          font-size: 22px;
          line-height: 27px;
        }
      }

      .text {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 25px;

        @include md {
          margin-bottom: 5px;
        }

        @include sm {
          line-height: 20px;
        }
      }

      @include xl {
        width: 40%;
        padding: 80px 50px 80px 30px;
      }

      @include lg {
        width: 45%;
        padding: 50px 50px 50px 0;
      }

      @include md {
        width: 100%;
        padding: 0;
      }
    }
  }

  .tab {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 25px 0;
    position: relative;

    &::after {
      @include md {
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        border-bottom: 1px solid $text;
        border-left: 1px solid $text;
        right: 5px;
        top: 28px;
        transform: rotate(-45deg);
        transition: transform 0.3s ease;
        z-index: -1;
      }
    }

    &-title {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      transition: color 0.3s ease;

      @include md {
        position: relative;

        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          background-color: rgba($text, 0.15);
          bottom: -25px;
          left: 0;
          right: 0;
        }
      }

      @include sm {
        font-size: 18px;
        line-height: 21px;
      }
    }

    &-line {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: rgba($text, 0.15);

      @include md {
        display: none;
      }
    }

    &-content {
      width: 51.64%;

      .tab-item {
        display: flex;
      }

      @include md {
        display: none;
      }
    }

    &.active {
      &::after {
        @include md {
          transform: rotate(135deg);
        }
      }

      .tab {
        &-title {
          color: $primary;

          @include md {
            color: $text;
          }
        }

        &-line {
          &::after {
            position: absolute;
            content: '';
            animation: progressBar 5s linear;
            height: 2px;
            background-color: $primary;
            top: 0;
            left: 0;
            right: 0;

            @include md {
              display: none;
            }
          }

          &.paused {
            &::after {
              animation-play-state: paused;
            }
          }
        }
      }

      @include md {
        padding-bottom: 15px;
      }
    }

    &:hover {
      .tab-title {
          color: $dark-violet;

        @include md {
          color: $text;
        }
      }
    }

    &-item {
      display: none;
      flex-direction: column;

      .image {
        object-fit: contain;
        margin-bottom: 30px;

        @include sm {
          margin-bottom: 20px;
        }
      }

      .text {
        @include md {
          margin-bottom: 0;
        }
      }

      @include md {
        margin-top: 55px;
        max-width: 600px;
        display: flex;
      }

      @include sm {
        margin-top: 45px;
      }
    }
  }
}
